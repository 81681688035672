/*<style>*/

@import "1-base.scss";

/*only11*/
 @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.el_logowrap .sub img {width: 100%;}
 }
 
 /*edge*/
@supports (-ms-ime-align:auto) {
	.colNumbersData .n input {font-size:1.3rem !important;}
}

/*@font-face {
	font-family: 'myfont';
	src: url(fonts/myfontregular.ttf);
	font-weight:400;
}

@font-face {
	font-family: 'myfont';
	src: url(fonts/myfontlight.ttf);
	font-weight:300;
}*/

html { font-size: 62.5%; }  /* 1 rem = 10px */
html, body {overflow-x:hidden;}

body {
	font-family: 'Montserrat', sans-serif;
	background: #fff;
	font-size:1.8rem;//18px if not defined in css (fallback)
	color: $color1;
	min-width: 320px;
	padding-top: 14rem;
}

img {
	max-width: 100%;
	height: auto;
}

a {color: $color1;}
a:hover {color: $color2;}
a {transition: color .1s ease-in, background .1s ease-in}

a:hover,
a:focus {
	outline: 0;
	text-decoration: none
}

h1, h2, h3, h4, h5, h6 {
	margin: 0;
}

.logo {transition:all .3s;}
.logo img {transition:all .3s;width: 41.4rem;}

.oberMenu .navbar {
	padding: 2rem 0 1rem 0;
	transition:all .3s;
	//border:1px solid red;
	background:#fff;
}

.shrink.oberMenu .navbar, .shrink.oberMenu .navbar.innerNavbar {
	padding-top: 0rem !important;
	padding-bottom: 0rem !important;
	//background: rgba(255, 255, 255, 0.9) !important;
	box-shadow: 0px 5px 5px 0px rgba(#ccc, 0.27);
	
	.logo {transform:scale(0.7);}
	.panelDaneHeader {margin: 1rem 0;}
}

/**/

img.alignright {
	float: right;
	margin: 0 0 2em 2em;
}

img.alignleft {
	float: left;
	margin: 0 2em 2em 0;
}

img.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.alignright {
	float: right;
}

.alignleft {
	float: left;
}

.aligncenter {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

a.fill_div {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
}

.relative {
	position: relative;
}

footer {
	margin-top: 9rem;
	padding: 5.5rem 0;
	background-color: #f9f9f9;
	
	.flexDaneWrap {justify-content:space-around;}
}

footer .partSocIcons .tw {margin-left: 0 !important;}

.podFooter {
padding: 2rem 0;	
color: $gray_light;
font-family: Montserrat;
font-size: 1.6rem;
font-weight: 300;

a {color: $gray_light; &:hover {color: $red;}}

.colPodfRight span {padding-right: 3rem; &:last-child{padding-right: 0;}}
}

/**/
.navigation {
	text-align: center;
	padding-top: 4rem;
	padding-bottom: 4rem;
	font-weight: 300;
	color: $gray;
}

.page-numbers {
	color: $gray;
	padding: 0 10px
}

.page-numbers:hover {color: $red;}
.page-numbers.current {font-weight: 700;color: #000;}
/**/

/**/
.formNestedRow {margin-top: 2rem;}
.formNestedRow .wpcf7-form {display: flex;flex-wrap:wrap;}
.formNestedRow .wpcf7 {width: 100%;}

.colWyslijKopie {
	font-size:0px;
	margin-bottom: 1.5rem;
	
	
	.doMnieCheck {
		font-size: 1.4rem;
		padding-left: 1rem;
		font-weight: 500;
		width: calc(100% - 30px);
	}
	
	.myCheckbox {
		width: 30px;
		label {margin-bottom: 0;}
		overflow:hidden;
		input[type="checkbox"] {display: none;}
		span.wpcf7-list-item-label::before {cursor:pointer;background:url(images/checkbox_off.png) no-repeat center;width: 30px;height: 30px;display: block;}
		input[type="checkbox"]:checked + span::before {
			background-image:url(images/checkbox_on.png);
		}		
	}
}

.formNestedRow input:hover, .formNestedRow textarea:hover, .formNestedRow input:focus, .formNestedRow textarea:focus, .formNestedRow select:hover {
	background-color: #F5F5F5;
}

.formNestedRow input, .formNestedRow textarea, .formNestedRow select {
	border:0;
	border-bottom: 1px solid $color2;
	background-color: transparent;
	width: 100%;
	padding: .7rem 1.7rem;
	margin-top: .5rem;
	margin-bottom: .5rem;
	font-size:1.8rem;
	transition:all .3s;
}

.formNestedRow select {
	outline:none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';	
	background-image:url(images/triangle_select.svg);
	background-position:right 15px center;
	background-repeat:no-repeat;
}

.formNestedRow textarea {
	resize: vertical;
	height: 100px;
	min-height: 100px;
	max-height: 200px;
	margin-bottom: 2rem;
	padding-top:1.7rem;
}

.formNestedRow input.wpcf7-submit {
	border:0;
	margin: 0;
	background: $color1;
	color: #ffffff;
	font-size: 1.8rem;
	padding: .7rem 1rem;
	font-weight: 400;
	display: block;
	cursor: pointer;
	transition:all .3s;
	border-radius:1rem;
}

.formNestedRow input.wpcf7-submit:hover {
	background-color: #000;
}

.wpcf7-validation-errors {
	//border-color: #CD373D !important
}

span.wpcf7-not-valid-tip {font-size:1.5rem !important;}
div.wpcf7-response-output {
	font-size:1.4rem !important;
	margin: 1rem 15px !important;
}

div.wpcf7 .ajax-loader {position:absolute;right: 0;bottom: 0;}

.colCaptcha {
	margin-bottom: .5rem;
	text-align: center;
	.wpcf7-recaptcha > div {display: inline-block;}
}

div.wpcf7 .wpcf7-submit:disabled {opacity:0.5;}

.inF ::-webkit-input-placeholder {opacity:1;color:$color2;}
.inF ::-moz-placeholder {opacity:1;color:$color2;}
.inF :-ms-input-placeholder {opacity:1;color:$color2;}
.inF :-moz-placeholder {opacity:1;color:$color2;}
/**/
.navbar-toggler {
	outline:none;
	cursor: pointer;
	position: relative;
	width: 43.2px;
	height: 40px;
	padding: 8px 12px;
	font-size: 20px;
	line-height: 16px;
	background: $color1;
	border-radius:0;
}

.navbar-toggler span {
	display: block;
	position: absolute;
	height: 3px;
	width: 50%;
	background: #fff;
	border-radius: 3px;
	opacity: 1;
	left: 25%;
	transform: rotate(0deg);
	transition: .25s ease-in-out
}

.navbar-toggler span:nth-child(1) {top: 33.5%}
.navbar-toggler span:nth-child(2), .navbar-toggler span:nth-child(3) {top: 50%}
.navbar-toggler span:nth-child(4) {top: 66.5%}
.navbar-toggler.open span:nth-child(1) {
	top: 18px;
	width: 0%;
	left: 50%
}

.navbar-toggler.open span:nth-child(2) { transform: rotate(45deg)}
.navbar-toggler.open span:nth-child(3) {transform: rotate(-45deg)}
.navbar-toggler.open span:nth-child(4) {
	top: 18px;
	width: 0%;
	left: 50%
}
/**/

ul.slick-dots {
	position:absolute;
	/*transform:translateY(calc(-50% - 140px));*/
	margin:0 auto;
	right: 0;
	left: 0;
	text-align:center;
	padding: 0;
	
	li {display:inline;padding:0 10px;
		
		button {background:url(images/dot.svg) no-repeat center / contain;border:0;border-radius:50%;font-size:0px;height:15px;width:15px;cursor:pointer;outline:none;}
		
		&.slick-active button {background-image:url(images/dot_active.svg);height:15px;width:15px;}
	}
}

.gotoBottomWrap {margin-bottom: 2rem;}
.wrapSliderHome {
	position:relative;
	
	
	.prevSlid, .nextSlid {@include v-center(absolute); z-index:5;cursor:pointer;width: 5rem;transition:all .2s; &:hover {opacity:0.5;}}
	.prevSlid {left: 2rem;}
	.nextSlid {right: 2rem;}
}

.wrapSliderHome {
	position:relative;
	ul.slick-dots {
		top:auto;
		bottom: 3rem;
	}
}
/* ==== Slider Style === */

.n1, .opisslid, .btnWrapSlid {
	opacity:0;
	animation:hideImage .5s, translateXBack .9s forwards;
}

.imgSlid {
	opacity:0;
	animation:hideImage .5s, translateXBack1 .9s forwards;
}

.slick-active {
	.n1 {animation: showImage .9s .3s forwards, translateXForward .5s .2s forwards;}
	.opisslid {animation: showImage .9s .3s forwards, translateXForward .7s .3s forwards;}
	.imgSlid {animation: showImage .9s .3s forwards, translateXForward1 .7s .3s forwards;}
	.btnWrapSlid {animation: showImage .5s .9s forwards, translateXForward .5s .2s forwards;}
}


.Modern-Slider{
	
	visibility:hidden;
	&.slick-initialized {visibility:visible;}	
	position:relative;
	
	-webkit-user-select: text !important;
	-khtml-user-select: text !important;
	-moz-user-select: text !important;
	-ms-user-select: text !important;
	user-select: text;
	
	.subFill {
		position:absolute;
		height: 100%;
		width: 75%;
		top: 0;
		right: 0;
		background-size:cover;
	}
	
	.img-fill {
		//height: calc(50vh);
		min-height:500px;
		max-height:1500px;		
		/*height: 640px;*/
		/*background-size:cover;
		background-attachment:fixed;*/
		/*background-color:#000;*/
		position:relative;
		overflow:hidden;
		
		.info {
			//@include v-center(absolute);
			//transform:translateY(calc(-50% + 5rem));
			left: 0px;
			width: 100%;
			z-index:50;
			color: $gray;
			padding-top: 6rem;
		}
		
		.n1 {
			//visibility:hidden;
			font-size: 2.2rem;
			font-weight: 600;	
			line-height: 1;
			color: $red;
			font-family: Montserrat;
			font-size: 2.5rem;
			font-weight: 400;
			margin-bottom: 3rem;
			margin-top: 6rem;
			
		}
		
		.opisslid {
			//visibility:hidden;
			font-size: 2rem;	
			width: 100%;
			color: $gray;
			font-family: Montserrat;
			font-size: 6rem;
			font-weight: 400;
			line-height: 1.3;
			margin-bottom: 6rem;

			strong {font-weight: 700; position:relative; &:after {content:'';display: block;background:red;width: 100%;height: 1.8rem;background-color: #e2e2e2;z-index:-1;left:0;bottom: .5rem;position:absolute;}}
		}
		
		.btnSlidAnim {
			//visibility:hidden;
			color: $gray;
			font-family: Montserrat;
			font-size: 2rem;
			font-weight: 400;
			position:relative;
			padding-left: 13rem;
			&:after {transition:all .5s;position:absolute;top: 50%;left: 0;width: 10rem;content:'';height: 1px;background:$red;}
			&:before {position:absolute;top: 50%;left: 0;width: 0rem;content:'';height: 1px;background:$gray;z-index:2;transition:all .5s;}
			
			&:hover {color: $red; &:before {width: 10rem;} &:after {right: 0;width: 0rem;}}
		}
		
		.btnWrapSlid {margin-bottom: 15rem;}
		
	}/*imgfill*/
}


/* ==== Slick Slider Css Ruls === */
.slick-slider{position:relative;display:block;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-touch-callout:none;-khtml-user-select:none;-ms-touch-action:pan-y;touch-action:pan-y;-webkit-tap-highlight-color:transparent}
.slick-list{position:relative;display:block;overflow:hidden;margin:0;padding:0}
.slick-list:focus{outline:none}.slick-list.dragging{cursor:hand}
.slick-slider .slick-track,.slick-slider .slick-list{-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0)}
.slick-track{position:relative;top:0;left:0;display:block}
.slick-track:before,.slick-track:after{display:table;content:''}.slick-track:after{clear:both}
.slick-loading .slick-track{visibility:hidden}
.slick-slide{display:none;float:left /* If RTL Make This Right */ ;height:100%;min-height:1px}
.slick-slide.dragging img{pointer-events:none}
.slick-initialized .slick-slide{display:block}
.slick-loading .slick-slide{visibility:hidden}
.slick-vertical .slick-slide{display:block;height:auto;border:1px solid transparent}
/**/

/*slick several like logas*/
.subwrapClientImg {
	padding-right: 30px;
	padding-left: 30px;
	text-align:center;
	height: 130px;
	line-height:130px;
}


.subwrapClientImg img {
	display:inline;
	max-height:100%;
}

/**/

/**/
.wrapPytanie {
	background:#fff;
	margin-bottom: 2rem;
	position:relative;
}

$com_padd: 3rem;

.trescakk {
	position:relative;
	cursor:pointer;
	color: $color1;
	font-size: 3rem;
	font-weight: 700;
	padding: 3rem 90px 3rem $com_padd;
	background:#f9f9f9 url(images/plusfaq.svg) no-repeat right 25px center;
	background-size:4.9rem;
	&:after {content:'';left: $com_padd;right: $com_padd;bottom: 0;height: 2px;background:rgba(#fff,0.4);position:absolute;}
}

.trescakk[aria-expanded="true"] {
	background-image:url(images/minusfaq.svg);
	background-color:$color1;
	color: #fff;
}

.subInCollapse {
	background:$color1;
	color:#fff;
	padding: 3rem $com_padd;
}

/**/
.tos-caption {display: none;}

.plastmb0 p:last-child{margin-bottom: 0;}
.plastmb0 ul:last-child{margin-bottom: 0;}
/**/

.acf-map {height: 20rem;}

#catapult-cookie-bar {font-size: 13px !important;}

/**/
.panelDaneHeader, footer {
	.partSocIcons {
		.tw {width: 2.4rem;margin: 0 3rem 0 6.5rem;}
		.linked {width: 2rem;}
		.tw, .linked {transition:all .2s; &:hover {opacity:0.5;}}
	}
}
.panelDaneHeader {margin-bottom: 2rem;transition:all .3s;}
/**/
.oberMenu .ubermenu {background:$red; &:after {content:'';position:absolute;top: 0;left: 100%;height: 100%;width: 150%;background:$red;display: block;}}
.oberMenu .ubermenu .ubermenu-nav{display:flex;align-items:center}
.oberMenu .ubermenu .ubermenu-nav > li > a:hover,.oberMenu .ubermenu .ubermenu-nav > li > a:focus,.oberMenu .ubermenu .ubermenu-nav > li > a:active{background:0}
.oberMenu .ubermenu .ubermenu-nav > li > a{padding: 2rem 2.5rem;}
.oberMenu .ubermenu .ubermenu-nav > li a{color:#fff;font-size:1.6rem;font-weight:300}
.oberMenu .ubermenu .ubermenu-nav > li a:hover{color:$gray;}
.oberMenu .ubermenu .ubermenu-nav > li:last-child {margin-left: 3rem;}
.oberMenu .ubermenu .ubermenu-nav > li:last-child > a {padding-left: 2rem;padding-right: 2rem; /*right space to subtriangle*/}
.ubermenu-sub-indicators .ubermenu-submenu-type-flyout > .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator,.ubermenu-sub-indicators .ubermenu-submenu-type-stack > .ubermenu-has-submenu-drop.ubermenu-has-submenu-flyout > .ubermenu-target > .ubermenu-sub-indicator{margin-top:-2px !important;right:10px !important}
.ubermenu-current-menu-item > a{color:#fff !important;font-weight:700 !important}
.oberMenu .ubermenu .ubermenu-sub-indicator{transition:all .3s;width:1.2rem;height:.6rem;background:url(images/sub_menu_new.svg) no-repeat center;background-size:contain;color:transparent;transform:translateY(-50%);margin-top:0 !important;right:0px !important;}
.ubermenu .ubermenu-submenu .ubermenu-target{padding:1rem 2rem}
.ubermenu-skin-minimal .ubermenu-submenu.ubermenu-submenu-drop{border:0 !important}
.oberMenu .ubermenu-submenu > li {background:$gray !important;}
.oberMenu .ubermenu-submenu > li a:hover {color: $red !important;}
/*.ubermenu-submenu > li:last-child{border-bottom:5px solid #97291e;padding-bottom:5px}*/
.ubermenu-item-level-2{box-shadow:1px 11px 29px rgba(0, 0, 0, 0.1);}
.ubermenu-item-level-2:last-child{border-bottom:0 !important}
.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item {min-width:auto !important;}
//.ubermenu .ubermenu-target-text {color: #fff !important;}
.pll-parent-menu-item > a {color: #fff !important;font-weight: 700 !important; > span {border-bottom:1px solid $gray;}}
/**/

.flexDaneWrap {
	&.myheader {
		.phone {margin-right: 5rem;}
	}
	.dane {
		font-size:1.6rem;
		color: $gray; a {color: $gray; &:hover {color: $red;}}
		display: flex;flex-wrap:wrap;align-items:center;
		p {margin-bottom: 0;}
		
		&.adres, &.phone, &.email {img {margin-right: 2rem;}}
		&.adres {img {width: 2rem;}}
		&.phone {img {width: 2.6rem;}}
		&.email {img {width: 3rem;}}
	}
}

.colPodSliderIco {
	display: flex;
	align-items:center;
	color: $gray;
	font-family: Montserrat;
	font-size: 1.6rem;
	justify-content:center;
	
	.im {max-width:4rem;margin-right: 2.5rem;}
}

.colPodSliderIco:nth-of-type(2n) {
	justify-content:center;
	border:1px solid $red;border-top:0;border-bottom:0;
}

.gobottomlink {
	transition:all .3s;
	&:hover {opacity:0.5;}
	
	img {width: 2.6rem;}
}

.haslored {
color: $red;
font-family: Montserrat;
font-size: 2rem;
font-weight: 300;
//text-transform: uppercase;	
}
.haslogray {
color: $gray;
font-family: Montserrat;
font-size: 4.5rem;
font-weight: 300;
}

.opis {
h1,h2,h3,h4,h5,h6 {margin-bottom: 1rem;}	
&.fs16 {font-size:1.6rem;}
&.fs18 {font-size:1.8rem;}
&.dark {color: $gray;}
color: $gray_light;
font-family: Montserrat;
font-size: 1.8rem;
font-weight: 300;
line-height: 1.6;
strong {font-weight: 500;}

&.single {p {margin-bottom: 3rem;font-size:1.6rem;line-height: 1.9;}}
p {margin-bottom: 2rem;}
.my_increase {font-size:2.2rem;margin-bottom: 3rem;}

ul {padding: 0;list-style-position:inside; li {margin-bottom: 1rem;}}

&.opisonas {ul {list-style:none; li {margin-bottom: 3rem;position:relative;padding-left: 9rem; &:after {position:absolute;top: 1.4rem;height: 1px;width: 6rem;left: 0;background:$red;content:'';}}}}
&.aicvonas {padding: 4rem 4rem;}
}

.rowOnas1 {margin-bottom: 8rem;}

.myBtn {
	display: inline-block;
	color: $gray;
	font-family: Montserrat;
	font-size: 1.6rem;
	font-weight: 300;
	padding: 1rem 3rem;
	border:1px solid;
	text-align: center;
	transition:all .2s;
	/*width: 100%;
	max-width:25rem;*/
	&.w {color: #fff; &:hover {background:$red;color: #fff;border-color:$red}}
	&.r {color: $red; &:hover {color: $gray;} &:before {display: none;}}
	&.rez {border:0;border-bottom:1px solid; color: $gray; &:hover {color: $red;} &:before {display: none;}}
}

.myBtn {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;	
}
.myBtn:before {
  content: '';
  position: absolute;
  border: solid 1px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}
.myBtn:hover:before, .myBtn:focus:before, .myBtn:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.btnRed {
	color: $gray;
	font-family: Montserrat;
	font-size: 1.9rem;
	font-weight: 400;
	position:relative;
	padding-left: 13rem;
	&:after {transition:all .5s;position:absolute;top: 50%;left: 0;width: 10rem;content:'';height: 1px;background:$red;}
	&:before {position:absolute;top: 50%;left: 0;width: 0rem;content:'';height: 1px;background:$gray;z-index:2;transition:all .5s;}
	
	&:hover {color: $red; &:before {width: 10rem;} &:after {right: 0;width: 0rem;}}
	&.fw500 {font-weight: 500;}
}

.oradziesek {margin-bottom: 7rem;}

.czym_nagl {
color: $gray;
font-family: Montserrat;
font-size: 1.8rem;
font-weight: 500;
position:relative;
padding-bottom: 2.5rem;
margin-bottom: 2.5rem;
margin-top: 2.5rem;

&:after {content:'';position:absolute;width: 9rem;height: 1px;background:$red;bottom: 0;left: 0;}
}

.colCzym {
	margin-bottom: 8rem;
	
	.ic {width: 9.8rem;height: 9.8rem;}
}

.newsSection {
background-color: #faf9fb;
padding: 4rem 0;
}

.calWrap {
color: $gray_light;
font-family: Montserrat;
font-size: 1.5rem;
font-weight: 300;
margin-bottom: 2rem;

img {width: 3.5rem;margin-bottom: 1rem;}
display: inline-block;
text-align: center;

&.black {color: $gray;}
}

.colNewsHome {
	.title {
		color: $gray;
		font-family: Montserrat;
		font-size: 2rem;
		font-weight: 300;
		margin-bottom: 2rem;
		line-height: 1.5;
	}
}

.moreHomeNews a {
font-family: Montserrat;
font-size: 2rem;
font-weight: 400;	
}

.colFirstNewsLeft {
	.sub {
	border: 1px solid #d4d3d5;
	background-color: #FBFBFC;
	color: #000;
	padding: 3rem;
	height: 100%;
	}
	.c {width: 5rem;margin-bottom: 1.5rem;}
}

.colFirstNewsRight {
	.sub {height: 100%;background:#fff;padding: 2.5rem 3rem;}
}

.archiveNews {
	margin-bottom: 16px;
	.colFirstNewsRight .sub {background-color: #fbf9fb;}
	.opis {font-size:1.6rem;}
	
	&.with_thumb {
		.sub {background-size:cover;background-position:center;position:relative;color: #fff;}
		.mask {
			background-color: $gray;
			opacity: 0.8;
			position:absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}


.wrapSlickMediaHome {
	position:relative;
	.prevSlid, .nextSlid {@include v-center(absolute); z-index:5;cursor:pointer;width: 1.6rem;transition:all .2s; &:hover {opacity:0.5;}}
	.prevSlid {left: -5rem;}
	.nextSlid {right: -5rem;}
	
.appDotsMedia {
	margin-top: 6rem;
	text-align: center;
	position:relative;
	.sub {
		display: inline-block;
		position:relative;
		ul.slick-dots {position:relative;bottom: .5rem;top: auto;}
	}
}
}

.wrapSlickMediaHome {.slick-list {margin-left: -13px;margin-right: -13px;}
.slick-track{
    display: flex;

    .slick-slide{
        display: flex;
        height: auto;
    }
}	
}

.mediaNewsCommonCol {
	padding-left: 13px;
	padding-right: 13px;
	.sub {
		background-color: #fbf9fb;
		width: 100%;
		height: 100%;
		padding: 3rem;
		display: flex;
		flex-direction:column;
	}
}

.titleMediaCol { 
margin-bottom: 3rem;	
a {
color: $gray;
font-size: 2.5rem;
font-weight: 500;

&:hover {color: $red;}
}
}

.colLinkPage {
	margin-bottom: 16px;
	.sub {
		height: 100%;position:relative;
		background-size:cover;
		background-position:center;
		text-align: center;
		padding: 5rem 2rem 5rem 2rem;
		
		.mask {
			position:absolute;
			width: 100%;
			height: 100%;
			background-color: rgba($gray, 0.62);
			top: 0;left: 0;
			z-index:1;
		}
		
		.haslo1 {
			color: $red;
			font-family: Montserrat;
			font-size: 2rem;
			font-weight: 300;
			text-transform: uppercase;
			margin-bottom: 0rem;
		}
		.haslo2 {
			color: #ffffff;
			font-family: Montserrat;
			font-size: 4.5rem;
			font-weight: 300;	
			margin-bottom: 3rem;
		}
		.ic img {
			width: 5rem;
		}
	}
}

.logoF {
	width: 27rem;
}

#myModal, .mymodal { 
	.modal-dialog {
		max-width:109rem;
		border:0;
		
		.modal-content {padding: 5rem 12rem;border-radius:0;border:0;box-shadow: 0 0 9rem rgba(0, 0, 0, 0.07);}
	}
}

//.modal-backdrop {background:none;}
.modal-open,
.modal.fade.show {padding-right: 0px !important;}
.modal-open .innerNavbar, .modal-open .navbar {padding-right: 0 !important;}

.zgoda_naglowek {
color: $red;
font-family: Montserrat;
font-size: 2.4rem;
font-weight: 500;
margin-bottom: 3rem;	
}

.zgoda_tekst {
color: $gray;
font-family: Montserrat;
font-size: 1.8rem;
font-weight: 300;
strong {font-weight: 500;}

a {text-decoration:underline;}
p {margin-bottom: 2rem;}
margin-bottom: 5rem;
}

.dataZgoda {margin-bottom: 5rem;}
.zgoda_tekst_data {
color: $gray;
font-family: Montserrat;
font-size: 1.6rem;
font-weight: 300;
}

.colNumbersData .n input {
	width: 5rem;
	border:0;
	border-bottom:2px solid $gray_light;
	margin: 0 1rem;
	text-align: center;
	color: $red;
	font-size:1.8rem;
	
	&:focus {	
		border-color:$red;
	}
}

.colNumbersData {
::-webkit-input-placeholder {opacity:1;color: $gray_light;font-size: 1rem !important;font-weight: 300;}
::-moz-placeholder {opacity:1;color: $gray_light;font-size: 1rem !important;font-weight: 300;}
:-ms-input-placeholder {opacity:1;color: $gray_light;font-size: 1rem !important;font-weight: 300;}
:-moz-placeholder {opacity:1;color: $gray_light;font-size: 1rem !important;font-weight: 300;}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}	

input[type=number] {
    -moz-appearance:textfield;
}
}

.contBread {
padding-top: 4rem;
margin-bottom: 4rem;
color: $gray_light;
font-family: Montserrat;
font-size: 1.6rem;
font-weight: 300;
a {color: $gray_light; &:hover {color: $red;}}
}

.headmain {
color: $gray;
font-family: Montserrat;
font-size: 6rem;	
font-weight: 700;
margin-bottom: 5rem;

span {position:relative; &:after {content:'';display: block;background:red;width: 100%;height: 1.8rem;background-color: #e2e2e2;z-index:-1;left:0;bottom: .5rem;position:absolute;}}
}

.sekBannerOnas {
	padding: 10rem 0;
	position:relative;
	color: #ffffff;
	font-family: Montserrat;
	font-size: 1.8rem;
	font-weight: 300;
	background-attachment:fixed;
	background-size:cover;
	background-position:center;
	margin-bottom: 10rem;
	
	.mask {
		position:absolute;
		background-color: rgba($gray, 0.82);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	
	.bannerop {
		padding-top: 5rem;
		position:relative;
		&:after {content:'';position:absolute;height: 1px;background:#fff;top: 0;left: 50%;transform:translateX(-50%);width: 21rem;}
	}
}

.contAICVonas {
	.sub {
		padding: 4rem 0;
		&:before {content:'';position:absolute;border:1px solid $red;top: 0;left: 0;width: calc(100% - 11rem);height: 100%;}
	}
	.acvImg {width: 22rem;}
	margin-bottom: 14rem;
}

.contLinkiOnas {margin-bottom: 14rem;}

.nazwa_zakladu {
color: $gray;
font-family: Montserrat;
font-size: 3rem;
font-weight: 300;
margin-bottom: 3rem;
position:relative;
padding-bottom: 2rem;

&:before {content:'';position:absolute;bottom: 0;left: 0;height: 1px;background:$red;width: 8.7rem;}
}

.rowPracownicy {margin-bottom: 5rem;}
.contPracownicy {margin-top: 5rem;}
.colPracownicy {
	margin-bottom: 6rem;
	.pracImg {
		width: 22rem;
		height: 22rem;
		object-fit:cover;
		margin-bottom: 3rem;
	}
	.name {
		color: $gray;
		font-size: 1.8rem;
		font-weight: 500;
	}
	.name, .st, .m {margin-bottom: 1rem;}
	.st, .m {font-size: 1.6rem;font-weight: 300;background-repeat:no-repeat;padding-left: 2.5rem;}
	.st {background-image:url(images/icon_man.svg);background-size:1.6rem;background-position:left .4rem;}
	.m {background-image:url(images/icon_home.svg);background-size:1.6rem;background-position:left .5rem;}
}



.colCzlonkowieH {
	margin-bottom: 6rem;
	
	.n {
		color: $gray;
		font-size: 1.8rem;
		font-weight: 500;	
		box-shadow: 0 0 3rem rgba(0, 0, 0, 0.05);
		padding:2rem 15rem 2rem 3rem;
		position:relative;
		margin-bottom: 7rem;
		
		img {
			position:absolute;
			width: 9.2rem;
			height: 9.2rem;
			object-fit:cover;
			right: 4.5rem;
			top: -1.2rem;
		}
	}
}

.contczlHon {
	margin-bottom: 6rem;
}

.colwspol {
	.imgWrap {
		height: 14rem;
		margin-bottom: 4rem;
		img {max-height:100%;}
	}
	.n {font-weight: 700;}
	margin-bottom: 5rem;
}

.mapkontakt {
	position:relative;
	iframe {width: 100%;height: 100%;min-height:300px;border:0;}
	&:before {content:'';position:absolute;height: 100%;width: 1px;background:#fff;right: 0;top: 0;}
}

.kontakt {
	.flexDaneWrap {flex-direction:column;
		.dane {margin-bottom: 5rem;font-size:2rem;}
		.dane.adres img {width: 3rem;}
		.dane.phone img {width: 3.4rem;}
		.dane.email img {width: 3.9rem;}
	}
}

.flexgrow1 {flex-grow:1;}

.kontakt {
	.tw, .linked {transition:all .1s; &:hover {opacity:0.5;}}
	.tw {margin-right: 4rem;}
}

.colRaporty {
	position:relative;
	
	&:nth-of-type(4n+2) .sub, &:nth-of-type(4n+3) .sub {background-color: #fbf9fb;}
	
	.sub {
		height: 100%;padding: 5rem 5rem;
		display: flex;flex-direction:column;
	}
	.subsub {
		/*background:red;*/height: 100%;
		display: flex;
		flex-direction:column;
		
		.o {padding-left: 8rem;margin-bottom: 3rem;}
	}
	.n {
		min-height:11.5rem;
		color: $gray;
		font-family: Montserrat;
		font-size: 2.5rem;
		font-weight: 300;		
		background:url(images/download_arrow.svg) no-repeat;
		background-size:4.2rem;
		background-position:0 50%;
		padding: 2rem 2rem 2rem 8rem;
	}
}

.mapWrapInbl {/*border:1px solid;*/width: 100%;display: inline-block;position:relative;}
.el_logowrap {
	position:absolute;
	.sub {position:relative;
		width: 12.4rem;
		height: 7.4rem;
		padding: 1rem;
		display: flex;justify-content:center;align-items:center;
		img {max-height:100%;}
		.mybg {background:#fff;width: 100%;height: 100%;position:absolute;top: 0;left: 0;}
		.mask {background:rgba($red, 0.5) url(images/winemask.svg) no-repeat center;background-size:5rem;width: 0%;height: 100%;position:absolute;top: 0;left: 0;transition:all .3s;}
		
		.ellipse {
			width: 1.6rem;
			height: 1.6rem;
			position:absolute;
			background:$red;
			display: block;
			border-radius:50%;
			transition:all .3s;

			&.left {
				transform:translate(-50%, -50%);
				top: 50%;
				left: 0;
			}
			&.top {
				transform:translate(-50%, -50%);
				top: 0%;
				left: 50%;
			}
			&.right {
				transform:translate(50%, -50%);
				top: 50%;
				right: 0%;
			}
			&.bottom {
				transform:translate(-50%, 50%);
				bottom: 0;
				left: 50%;
			}			
		}
		
		&:hover {
			.ellipse {background:$gray;}
			.mask {width: 100%;}
		}
	}
}

.colOpisyCzl {
	.wrapImgC {height: 12rem; img {max-height:100%;}
		position:relative;
		padding-bottom: 3rem;
		margin-bottom: 3rem;
		&:before {content:'';position:absolute;bottom: 0;left: 0;background:$red;width: 9rem;display: block;height: 1px;}
	}
}

.singlehead {
	color: $gray;
	font-family: Montserrat;
	font-size: 2.5rem;
	font-weight: 500;
	line-height: 1.3;
}

.colInArchMeda {
	margin-bottom: 26px;
}

.kDlaMediow .flexDaneWrap {
	justify-content:space-around;
	
	.dane {font-size:3.3rem;}
	.dane.phone img {width: 6.4rem;}
	.dane.email img {width: 6.4rem;}
}

.wydZRoku {
color: $gray_light;
font-family: Montserrat;
font-size: 1.8rem;
font-weight: 300;
padding-left: 4rem;
border-left:1px solid $red;


	.byYear {
		display: flex;justify-content:flex-end;
		li {padding: 0;list-style:none;margin-right: 3rem;
			&.act {font-weight: 500;}
			&:last-child {margin-right: 0;}
		}
	}
}

#validMessage {
	/*border:1px solid $red;*/
	color: $gray;
	font-weight:300;
	margin-top: 3rem;margin-bottom: 0rem;
	
	span {
		background:$red;
		border-radius:50%;
		display: block;
		width: 5rem;
		height: 5rem;
		line-height: 5rem;
		color: #fff;
		transition:all .3s;
		opacity:0;
		transform:scale(0);
		
		&.act {opacity:1;transform:scale(1);}
	}
}

.mobileDaneHeader {
	display: none;
	.flexDaneWrap.myheader {margin-top: 1rem;}
	.flexDaneWrap.myheader .phone {margin-right: 2rem;}	
	
	.partSocIcons {margin-top: 1rem;margin-bottom: 1rem;}
	.partSocIcons img {width: 3rem;margin-right: 2rem;}
	
	&.show {display: block;}
}

.xMapMobile {
	padding-top: 10px;
	button {color: $red;font-size:25px;}
}

.imgLogoMobile {
	transform:scale(0.8);
}

@media (max-width:1879.98px) {
	html {font-size:55%;}
}
@media (max-width:1589.98px) {
	html {font-size:50%;}
	.oberMenu .ubermenu .ubermenu-nav > li > a{padding-left:2.0rem;padding-right:2.0rem}
	.oberMenu .ubermenu .ubermenu-sub-indicator{right:2px !important}
	.oberMenu .ubermenu .ubermenu-nav > li:last-child > div{padding-left:2rem !important}
	/**/
	.logo img {width: 34rem;}
	.Modern-Slider .img-fill .btnWrapSlid {margin-bottom: 5rem;}
	.Modern-Slider .img-fill .opisslid {font-size:5rem;}
	/**/
	.el_logowrap .sub .mask {background-size:4rem;}
	.el_logowrap .sub {width: 10.4rem;height: 6.4rem;}
}

@media (max-width: 1199.98px) {
.oberMenu .ubermenu .ubermenu-nav > li:last-child {margin-left: 0;}
.oberMenu .ubermenu .ubermenu-nav > li > a{padding-left:1.0rem;padding-right:1.0rem}
.logo img {width: 25rem;}
.cf1 {order:-2;}
.cf3 {order:-1;}
footer .flexDaneWrap {justify-content:space-between;}
.cf1, .cf3 {margin-bottom: 15px;}
.colRaporty .sub {padding: 3rem;}
} /*1200*/

@media (max-width: 991.98px) {
.el_logowrap .sub .mask {background-size:3rem;}	
.el_logowrap .sub {width: 7.4rem;height: 5.4rem;}	
.colLinkPage .sub .haslo2 {font-size:3.5rem;}
.oradzieimghome {position:relative;right: 15px;}	
.siblingLogo {width: 100%;}
.logo {align-self:stretch;position:absolute;top: 0;left: 15px;}
.logo img {width: 15rem;}
.shrink.oberMenu .navbar, .shrink.oberMenu .navbar.innerNavbar {.logo {transform:scale(1);top: 5px;}}
.Modern-Slider .img-fill .info {padding-top: 1rem;}
.Modern-Slider .img-fill .opisslid {font-size:3.5rem;}
.colPodSliderIco {justify-content:flex-start !important;border:0 !important;margin-bottom: 15px;}
.Modern-Slider .img-fill .n1 {margin-top: 3rem;}
.Modern-Slider .img-fill .btnWrapSlid {margin-bottom: 3rem;}
.Modern-Slider .img-fill .opisslid {margin-bottom: 3rem;}

.wrapSliderHome {
	.prevSlid, .nextSlid {
		background: #fff;
		border: 1px solid $red;
		border-radius: 50%;
		padding: 1rem;		
	}
	.prevSlid {left: 1rem;}	
	.nextSlid {right: 1rem;}
}
.kDlaMediow {
   .flexDaneWrap .dane {font-size: 2.5rem;}
   .flexDaneWrap .dane.phone img {width: 4rem;}
   .flexDaneWrap .dane.email img {width: 4rem;}
}
.colRaporty {
	.sub {background-color: #fff !important;border:1px solid #fbf9fb;}
	&:nth-of-type(even) .sub {background-color: #fbf9fb !important;}
	.n {min-height:auto;}
	margin-bottom: 15px;
}
.colRaporty .subsub .o {padding-left: 0;}
#myModal .modal-dialog .modal-content, .mymodal .modal-dialog .modal-content {padding: 3rem;}
} /*992*/

@media (max-width: 767.98px) {
/**/
.mapWrapInbl {
width: calc(100% + 30px);
right: 15px;	
}
/**/
.colKontaktRight {order:-1;}
.kDlaMediow {
.flexDaneWrap {
	flex-wrap:wrap;
	.dane {width: 100%;margin-bottom: 15px;justify-content:center;}
}}	
.navigation {padding-top: 0;padding-bottom: 0;}
.wydZRoku {padding-left: 2rem;margin-top:2rem;}
.wydZRoku .byYear {justify-content:flex-start;}
.el_logowrap .sub {
	border:1px solid $red;
	background:$red;
	width: 20px;
	height: 20px;
	border-radius:50%;
	.mybg {display: none;}
	.mask {display: none;}
	.ellipse {display: none;}
	.mapklientimg {display: none;}
}	
.colwspol .imgWrap {height: auto;margin-bottom: 2rem; img {max-height:150px;}}
.colwspol {margin-bottom: 2rem;}
.colCzlonkowieH {margin-bottom: 2rem;}
.colCzlonkowieH .n {margin-bottom: 4rem;}
.allMetaOnas {display: flex;flex-direction:column;align-items:center;}	
.colPracownicy {margin-bottom: 3rem;}
.jakdzialamyimg {max-height:250px;}	
.contLinkiOnas {margin-bottom: 2rem;}
.contAICVonas {margin-bottom: 4rem;}
.sekBannerOnas {margin-bottom: 3rem;}
.contAICVonas .sub {padding: 2rem 0;}
.opis.aicvonas {padding: 0 2rem 2rem 2rem;}
.contAICVonas .sub::before {width: 100%;}	
.sekBannerOnas {padding-top: 5rem;padding-bottom: 5rem;}
.opis.opisonas ul li {padding-left: 5rem; &:after {width: 3rem;}}	
.contBread {padding-top: 2rem;margin-bottom: 2rem;}
.headmain {font-size:4rem;margin-bottom: 2rem;}
.colOnasRight {order:-1;}	
.podFooter .colPodfRight span {padding: 0 1rem !important;}	
footer .flexDaneWrap {flex-wrap:wrap; .dane {width: 100%;margin-bottom: 15px; &:last-child {margin-bottom: 0;}}}	
footer {padding-top: 2rem;padding-bottom: 2rem;}
footer {margin-top: 3rem;}	
.wrapSlickMediaHome .appDotsMedia {margin-top: 3rem;}
body {padding-top: 60px;}	
.oberMenu .ubermenu::after {display: none;}
.logo {display: none;}	
.navbar-expand-md > .container {padding-left: 15px;padding-right: 15px}
.navbar-expand-md > .container {padding-left: 15px;padding-right: 15px;}	
.ubermenu-skin-minimal.ubermenu-responsive-toggle{padding:0 !important;display:flex !important;flex-direction:row-reverse;justify-content:space-between;align-items:center;margin-bottom:10px;margin-top:10px;}
.ubermenu-skin-minimal.ubermenu-responsive-toggle .fa-bars{font-size:30px !important;color:#707070;margin:0 !important;}
.oberMenu .navbar{padding-top:1rem !important;padding-bottom:1rem !important;background:rgba(255, 255, 255, 0.9) !important;box-shadow:0px 5px 5px 0px rgba(204, 204, 204, 0.27);}
.logomobile{width:140px;}
.oberMenu .ubermenu .ubermenu-nav > li > a{padding:0 0 15px 0 !important;font-size:15px;}
.oberMenu .ubermenu .ubermenu-nav > li:last-child > div{padding:0 !important;}
.oberMenu .ubermenu .ubermenu-nav > li a{font-size:15px;}
.ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator{position:relative !important;left:7px !important;right:0 !important;transform:none !important;top:5px !important;}
.oberMenu .navbar {padding-top: 0 !important;padding-bottom: 0 !important;background:#fff !important;}
.mobileDaneHeader {.flexDaneWrap .dane.adres img, .flexDaneWrap .dane.phone img, .flexDaneWrap .dane.email img {margin-right: 1rem;}}
.oberMenu .ubermenu .ubermenu-nav > li a {font-size: 15px;font-weight: 400; padding: 8px 8px !important;}
.Modern-Slider .img-fill .n1 {margin-top: 1rem;margin-bottom: 1rem;}
.Modern-Slider .img-fill .opisslid {font-size: 3rem;}
.imgSlid {max-height:250px;}
.colPodSliderIco {font-size:2rem;}
.haslogray {font-size:3.5rem;}
.haslored {font-size:1.7rem;}
.colCzym {margin-bottom: 2rem;}
}  /*768*/

@media (max-width: 575.98px) {
	
}  /*576*/

@media (max-width: 480px) {
	
}  /*480*/

@media (max-width: 350px) {
	.mobileDaneHeader .dane img {display: none;}
}  /*350*/

@media (min-width: 1200px) {

} /*1200*/

/*</style>*/	