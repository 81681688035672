/*<style>*/

/*
	Theme Name: Website WiWi
	Theme URI:
	Author URI: wiwi
	Version: 9.9.9
	License: GNU General Public License, version 3 (GPLv3)
	License URI: license.txt
*/

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb25 {
  margin-bottom: 25px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb35 {
  margin-bottom: 35px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb45 {
  margin-bottom: 45px;
}

.mb50 {
  margin-bottom: 50px;
}

.mb55 {
  margin-bottom: 55px;
}

.mb60 {
  margin-bottom: 60px;
}

.mb65 {
  margin-bottom: 65px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb75 {
  margin-bottom: 75px;
}

.mb80 {
  margin-bottom: 80px;
}

.mb85 {
  margin-bottom: 85px;
}

.mb90 {
  margin-bottom: 90px;
}

.mb95 {
  margin-bottom: 95px;
}

.mb100 {
  margin-bottom: 100px;
}

.mt5 {
  margin-top: 5px;
}

.mt10 {
  margin-top: 10px;
}

.mt15 {
  margin-top: 15px;
}

.mt20 {
  margin-top: 20px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt35 {
  margin-top: 35px;
}

.mt40 {
  margin-top: 40px;
}

.mt45 {
  margin-top: 45px;
}

.mt50 {
  margin-top: 50px;
}

.mt55 {
  margin-top: 55px;
}

.mt60 {
  margin-top: 60px;
}

.mt65 {
  margin-top: 65px;
}

.mt70 {
  margin-top: 70px;
}

.mt75 {
  margin-top: 75px;
}

.mt80 {
  margin-top: 80px;
}

.mt85 {
  margin-top: 85px;
}

.mt90 {
  margin-top: 90px;
}

.mt95 {
  margin-top: 95px;
}

.mt100 {
  margin-top: 100px;
}

.pb5 {
  padding-bottom: 5px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb15 {
  padding-bottom: 15px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb25 {
  padding-bottom: 25px;
}

.pb30 {
  padding-bottom: 30px;
}

.pb35 {
  padding-bottom: 35px;
}

.pb40 {
  padding-bottom: 40px;
}

.pb45 {
  padding-bottom: 45px;
}

.pb50 {
  padding-bottom: 50px;
}

.pb55 {
  padding-bottom: 55px;
}

.pb60 {
  padding-bottom: 60px;
}

.pb65 {
  padding-bottom: 65px;
}

.pb70 {
  padding-bottom: 70px;
}

.pb75 {
  padding-bottom: 75px;
}

.pb80 {
  padding-bottom: 80px;
}

.pb85 {
  padding-bottom: 85px;
}

.pb90 {
  padding-bottom: 90px;
}

.pb95 {
  padding-bottom: 95px;
}

.pb100 {
  padding-bottom: 100px;
}

.pt5 {
  padding-top: 5px;
}

.pt10 {
  padding-top: 10px;
}

.pt15 {
  padding-top: 15px;
}

.pt20 {
  padding-top: 20px;
}

.pt25 {
  padding-top: 25px;
}

.pt30 {
  padding-top: 30px;
}

.pt35 {
  padding-top: 35px;
}

.pt40 {
  padding-top: 40px;
}

.pt45 {
  padding-top: 45px;
}

.pt50 {
  padding-top: 50px;
}

.pt55 {
  padding-top: 55px;
}

.pt60 {
  padding-top: 60px;
}

.pt65 {
  padding-top: 65px;
}

.pt70 {
  padding-top: 70px;
}

.pt75 {
  padding-top: 75px;
}

.pt80 {
  padding-top: 80px;
}

.pt85 {
  padding-top: 85px;
}

.pt90 {
  padding-top: 90px;
}

.pt95 {
  padding-top: 95px;
}

.pt100 {
  padding-top: 100px;
}

.z1 {
  z-index: 1;
}

.z2 {
  z-index: 2;
}

.z3 {
  z-index: 3;
}

.z4 {
  z-index: 4;
}

.z5 {
  z-index: 5;
}

.z6 {
  z-index: 6;
}

.z7 {
  z-index: 7;
}

.z8 {
  z-index: 8;
}

.z9 {
  z-index: 9;
}

.z10 {
  z-index: 10;
}

@keyframes pulsered {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.4);
  }

  20% {
    box-shadow: 0 0 0 20px rgba(255, 0, 0, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
  }
}

.col8 {
  padding-right: 8px;
  padding-left: 8px;
}

.row8 {
  margin-right: -8px;
  margin-left: -8px;
}

.col10 {
  padding-right: 10px;
  padding-left: 10px;
}

.row10 {
  margin-right: -10px;
  margin-left: -10px;
}

.col13 {
  padding-right: 13px;
  padding-left: 13px;
}

.row13 {
  margin-right: -13px;
  margin-left: -13px;
}

.col20 {
  padding-right: 20px;
  padding-left: 20px;
}

.row20 {
  margin-right: -20px;
  margin-left: -20px;
}

.col25 {
  padding-right: 25px;
  padding-left: 25px;
}

.row25 {
  margin-right: -25px;
  margin-left: -25px;
}

.col30 {
  padding-right: 30px;
  padding-left: 30px;
}

.row30 {
  margin-right: -30px;
  margin-left: -30px;
}

.col40 {
  padding-right: 40px;
  padding-left: 40px;
}

.row40 {
  margin-right: -40px;
  margin-left: -40px;
}

.col60 {
  padding-right: 60px;
  padding-left: 60px;
}

.row60 {
  margin-right: -60px;
  margin-left: -60px;
}

.col10 {
  padding-right: 10px;
  padding-left: 10px;
}

.row10 {
  margin-right: -10px;
  margin-left: -10px;
}

.col50 {
  padding-right: 50px;
  padding-left: 50px;
}

.row50 {
  margin-right: -50px;
  margin-left: -50px;
}

.col38 {
  padding-right: 38px;
  padding-left: 38px;
}

.row38 {
  margin-right: -38px;
  margin-left: -38px;
}

@keyframes showImage {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hideImage {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes scaleImage {
  0% {
    transform: scale(0);
    transform-origin: center;
  }

  100% {
    transform: scale(1);
    transform-origin: center;
  }
}

@keyframes scaleBackImage {
  0% {
    transform: scale(1);
    transform-origin: center;
  }

  100% {
    transform: scale(0);
    transform-origin: center;
  }
}

/**/

@keyframes translateXBack {
  0% {
    transform: translateX(0px);
    transform-origin: center;
  }

  100% {
    transform: translateX(-1000px);
    transform-origin: center;
  }
}

@keyframes translateXBack1 {
  0% {
    transform: translateX(0px);
    transform-origin: center;
  }

  100% {
    transform: translateX(1000px);
    transform-origin: center;
  }
}

@keyframes translateXForward {
  0% {
    transform: translateX(-1000px);
    transform-origin: center;
  }

  100% {
    transform: translateX(0px);
    transform-origin: center;
  }
}

@keyframes translateXForward1 {
  0% {
    transform: translateX(1000px);
    transform-origin: center;
  }

  100% {
    transform: translateX(0px);
    transform-origin: center;
  }
}

/*only11*/

/*edge*/

@supports (-ms-ime-align: auto) {
  .colNumbersData .n input {
    font-size: 1.3rem !important;
  }
}

/*@font-face {
	font-family: 'myfont';
	src: url(fonts/myfontregular.ttf);
	font-weight:400;
}

@font-face {
	font-family: 'myfont';
	src: url(fonts/myfontlight.ttf);
	font-weight:300;
}*/

html {
  font-size: 62.5%;
}

/* 1 rem = 10px */

html,
body {
  overflow-x: hidden;
}

body {
  font-family: 'Montserrat', sans-serif;
  background: #fff;
  font-size: 1.8rem;
  color: #434242;
  min-width: 320px;
  padding-top: 14rem;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: #434242;
}

a:hover {
  color: #de1c4a;
}

a {
  transition: color .1s ease-in, background .1s ease-in;
}

a:hover,
a:focus {
  outline: 0;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.logo {
  transition: all .3s;
}

.logo img {
  transition: all .3s;
  width: 41.4rem;
}

.oberMenu .navbar {
  padding: 2rem 0 1rem 0;
  transition: all .3s;
  background: #fff;
}

.shrink.oberMenu .navbar,
.shrink.oberMenu .navbar.innerNavbar {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
  box-shadow: 0px 5px 5px 0px rgba(204, 204, 204, 0.27);
}

.shrink.oberMenu .navbar .logo,
.shrink.oberMenu .navbar.innerNavbar .logo {
  transform: scale(0.7);
}

.shrink.oberMenu .navbar .panelDaneHeader,
.shrink.oberMenu .navbar.innerNavbar .panelDaneHeader {
  margin: 1rem 0;
}

/**/

img.alignright {
  float: right;
  margin: 0 0 2em 2em;
}

img.alignleft {
  float: left;
  margin: 0 2em 2em 0;
}

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

a.fill_div {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
}

.relative {
  position: relative;
}

footer {
  margin-top: 9rem;
  padding: 5.5rem 0;
  background-color: #f9f9f9;
}

footer .flexDaneWrap {
  justify-content: space-around;
}

footer .partSocIcons .tw {
  margin-left: 0 !important;
}

.podFooter {
  padding: 2rem 0;
  color: #757575;
  font-family: Montserrat;
  font-size: 1.6rem;
  font-weight: 300;
}

.podFooter a {
  color: #757575;
}

.podFooter a:hover {
  color: #de1c4a;
}

.podFooter .colPodfRight span {
  padding-right: 3rem;
}

.podFooter .colPodfRight span:last-child {
  padding-right: 0;
}

/**/

.navigation {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
  font-weight: 300;
  color: #434242;
}

.page-numbers {
  color: #434242;
  padding: 0 10px;
}

.page-numbers:hover {
  color: #de1c4a;
}

.page-numbers.current {
  font-weight: 700;
  color: #000;
}

/**/

/**/

.formNestedRow {
  margin-top: 2rem;
}

.formNestedRow .wpcf7-form {
  display: flex;
  flex-wrap: wrap;
}

.formNestedRow .wpcf7 {
  width: 100%;
}

.colWyslijKopie {
  font-size: 0px;
  margin-bottom: 1.5rem;
}

.colWyslijKopie .doMnieCheck {
  font-size: 1.4rem;
  padding-left: 1rem;
  font-weight: 500;
  width: calc(100% - 30px);
}

.colWyslijKopie .myCheckbox {
  width: 30px;
  overflow: hidden;
}

.colWyslijKopie .myCheckbox label {
  margin-bottom: 0;
}

.colWyslijKopie .myCheckbox input[type="checkbox"] {
  display: none;
}

.colWyslijKopie .myCheckbox span.wpcf7-list-item-label::before {
  cursor: pointer;
  background: url(images/checkbox_off.png) no-repeat center;
  width: 30px;
  height: 30px;
  display: block;
}

.colWyslijKopie .myCheckbox input[type="checkbox"]:checked + span::before {
  background-image: url(images/checkbox_on.png);
}

.formNestedRow input:hover,
.formNestedRow textarea:hover,
.formNestedRow input:focus,
.formNestedRow textarea:focus,
.formNestedRow select:hover {
  background-color: #F5F5F5;
}

.formNestedRow input,
.formNestedRow textarea,
.formNestedRow select {
  border: 0;
  border-bottom: 1px solid #de1c4a;
  background-color: transparent;
  width: 100%;
  padding: .7rem 1.7rem;
  margin-top: .5rem;
  margin-bottom: .5rem;
  font-size: 1.8rem;
  transition: all .3s;
}

.formNestedRow select {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  background-image: url(images/triangle_select.svg);
  background-position: right 15px center;
  background-repeat: no-repeat;
}

.formNestedRow textarea {
  resize: vertical;
  height: 100px;
  min-height: 100px;
  max-height: 200px;
  margin-bottom: 2rem;
  padding-top: 1.7rem;
}

.formNestedRow input.wpcf7-submit {
  border: 0;
  margin: 0;
  background: #434242;
  color: #ffffff;
  font-size: 1.8rem;
  padding: .7rem 1rem;
  font-weight: 400;
  display: block;
  cursor: pointer;
  transition: all .3s;
  border-radius: 1rem;
}

.formNestedRow input.wpcf7-submit:hover {
  background-color: #000;
}

span.wpcf7-not-valid-tip {
  font-size: 1.5rem !important;
}

div.wpcf7-response-output {
  font-size: 1.4rem !important;
  margin: 1rem 15px !important;
}

div.wpcf7 .ajax-loader {
  position: absolute;
  right: 0;
  bottom: 0;
}

.colCaptcha {
  margin-bottom: .5rem;
  text-align: center;
}

.colCaptcha .wpcf7-recaptcha > div {
  display: inline-block;
}

div.wpcf7 .wpcf7-submit:disabled {
  opacity: 0.5;
}

.inF ::-webkit-input-placeholder {
  opacity: 1;
  color: #de1c4a;
}

.inF ::-moz-placeholder {
  opacity: 1;
  color: #de1c4a;
}

.inF :-ms-input-placeholder {
  opacity: 1;
  color: #de1c4a;
}

.inF :-moz-placeholder {
  opacity: 1;
  color: #de1c4a;
}

/**/

.navbar-toggler {
  outline: none;
  cursor: pointer;
  position: relative;
  width: 43.2px;
  height: 40px;
  padding: 8px 12px;
  font-size: 20px;
  line-height: 16px;
  background: #434242;
  border-radius: 0;
}

.navbar-toggler span {
  display: block;
  position: absolute;
  height: 3px;
  width: 50%;
  background: #fff;
  border-radius: 3px;
  opacity: 1;
  left: 25%;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
}

.navbar-toggler span:nth-child(1) {
  top: 33.5%;
}

.navbar-toggler span:nth-child(2),
.navbar-toggler span:nth-child(3) {
  top: 50%;
}

.navbar-toggler span:nth-child(4) {
  top: 66.5%;
}

.navbar-toggler.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.navbar-toggler.open span:nth-child(2) {
  transform: rotate(45deg);
}

.navbar-toggler.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.navbar-toggler.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

/**/

ul.slick-dots {
  position: absolute;
  /*transform:translateY(calc(-50% - 140px));*/
  margin: 0 auto;
  right: 0;
  left: 0;
  text-align: center;
  padding: 0;
}

ul.slick-dots li {
  display: inline;
  padding: 0 10px;
}

ul.slick-dots li button {
  background: url(images/dot.svg) no-repeat center/contain;
  border: 0;
  border-radius: 50%;
  font-size: 0px;
  height: 15px;
  width: 15px;
  cursor: pointer;
  outline: none;
}

ul.slick-dots li.slick-active button {
  background-image: url(images/dot_active.svg);
  height: 15px;
  width: 15px;
}

.gotoBottomWrap {
  margin-bottom: 2rem;
}

.wrapSliderHome {
  position: relative;
}

.wrapSliderHome .prevSlid,
.wrapSliderHome .nextSlid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 5rem;
  transition: all .2s;
}

.wrapSliderHome .prevSlid:hover,
.wrapSliderHome .nextSlid:hover {
  opacity: 0.5;
}

.wrapSliderHome .prevSlid {
  left: 2rem;
}

.wrapSliderHome .nextSlid {
  right: 2rem;
}

.wrapSliderHome {
  position: relative;
}

.wrapSliderHome ul.slick-dots {
  top: auto;
  bottom: 3rem;
}

/* ==== Slider Style === */

.n1,
.opisslid,
.btnWrapSlid {
  opacity: 0;
  animation: hideImage .5s, translateXBack .9s forwards;
}

.imgSlid {
  opacity: 0;
  animation: hideImage .5s, translateXBack1 .9s forwards;
}

.slick-active .n1 {
  animation: showImage .9s .3s forwards, translateXForward .5s .2s forwards;
}

.slick-active .opisslid {
  animation: showImage .9s .3s forwards, translateXForward .7s .3s forwards;
}

.slick-active .imgSlid {
  animation: showImage .9s .3s forwards, translateXForward1 .7s .3s forwards;
}

.slick-active .btnWrapSlid {
  animation: showImage .5s .9s forwards, translateXForward .5s .2s forwards;
}

.Modern-Slider {
  visibility: hidden;
  position: relative;
  -webkit-user-select: text !important;
  -khtml-user-select: text !important;
  -moz-user-select: text !important;
  -ms-user-select: text !important;
  user-select: text;
  /*imgfill*/
}

.Modern-Slider.slick-initialized {
  visibility: visible;
}

.Modern-Slider .subFill {
  position: absolute;
  height: 100%;
  width: 75%;
  top: 0;
  right: 0;
  background-size: cover;
}

.Modern-Slider .img-fill {
  min-height: 500px;
  max-height: 1500px;
  /*height: 640px;*/
  /*background-size:cover;
		background-attachment:fixed;*/
  /*background-color:#000;*/
  position: relative;
  overflow: hidden;
}

.Modern-Slider .img-fill .info {
  left: 0px;
  width: 100%;
  z-index: 50;
  color: #434242;
  padding-top: 6rem;
}

.Modern-Slider .img-fill .n1 {
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1;
  color: #de1c4a;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 400;
  margin-bottom: 3rem;
  margin-top: 6rem;
}

.Modern-Slider .img-fill .opisslid {
  font-size: 2rem;
  width: 100%;
  color: #434242;
  font-family: Montserrat;
  font-size: 6rem;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 6rem;
}

.Modern-Slider .img-fill .opisslid strong {
  font-weight: 700;
  position: relative;
}

.Modern-Slider .img-fill .opisslid strong:after {
  content: '';
  display: block;
  background: red;
  width: 100%;
  height: 1.8rem;
  background-color: #e2e2e2;
  z-index: -1;
  left: 0;
  bottom: .5rem;
  position: absolute;
}

.Modern-Slider .img-fill .btnSlidAnim {
  color: #434242;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 400;
  position: relative;
  padding-left: 13rem;
}

.Modern-Slider .img-fill .btnSlidAnim:after {
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 0;
  width: 10rem;
  content: '';
  height: 1px;
  background: #de1c4a;
}

.Modern-Slider .img-fill .btnSlidAnim:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0rem;
  content: '';
  height: 1px;
  background: #434242;
  z-index: 2;
  transition: all .5s;
}

.Modern-Slider .img-fill .btnSlidAnim:hover {
  color: #de1c4a;
}

.Modern-Slider .img-fill .btnSlidAnim:hover:before {
  width: 10rem;
}

.Modern-Slider .img-fill .btnSlidAnim:hover:after {
  right: 0;
  width: 0rem;
}

.Modern-Slider .img-fill .btnWrapSlid {
  margin-bottom: 15rem;
}

/* ==== Slick Slider Css Ruls === */

.slick-slider {
  position: relative;
  display: block;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: '';
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/**/

/*slick several like logas*/

.subwrapClientImg {
  padding-right: 30px;
  padding-left: 30px;
  text-align: center;
  height: 130px;
  line-height: 130px;
}

.subwrapClientImg img {
  display: inline;
  max-height: 100%;
}

/**/

/**/

.wrapPytanie {
  background: #fff;
  margin-bottom: 2rem;
  position: relative;
}

.trescakk {
  position: relative;
  cursor: pointer;
  color: #434242;
  font-size: 3rem;
  font-weight: 700;
  padding: 3rem 90px 3rem 3rem;
  background: #f9f9f9 url(images/plusfaq.svg) no-repeat right 25px center;
  background-size: 4.9rem;
}

.trescakk:after {
  content: '';
  left: 3rem;
  right: 3rem;
  bottom: 0;
  height: 2px;
  background: rgba(255, 255, 255, 0.4);
  position: absolute;
}

.trescakk[aria-expanded="true"] {
  background-image: url(images/minusfaq.svg);
  background-color: #434242;
  color: #fff;
}

.subInCollapse {
  background: #434242;
  color: #fff;
  padding: 3rem 3rem;
}

/**/

.tos-caption {
  display: none;
}

.plastmb0 p:last-child {
  margin-bottom: 0;
}

.plastmb0 ul:last-child {
  margin-bottom: 0;
}

/**/

.acf-map {
  height: 20rem;
}

#catapult-cookie-bar {
  font-size: 13px !important;
}

/**/

.panelDaneHeader .partSocIcons .tw,
footer .partSocIcons .tw {
  width: 2.4rem;
  margin: 0 3rem 0 6.5rem;
}

.panelDaneHeader .partSocIcons .linked,
footer .partSocIcons .linked {
  width: 2rem;
}

.panelDaneHeader .partSocIcons .tw,
.panelDaneHeader .partSocIcons .linked,
footer .partSocIcons .tw,
footer .partSocIcons .linked {
  transition: all .2s;
}

.panelDaneHeader .partSocIcons .tw:hover,
.panelDaneHeader .partSocIcons .linked:hover,
footer .partSocIcons .tw:hover,
footer .partSocIcons .linked:hover {
  opacity: 0.5;
}

.panelDaneHeader {
  margin-bottom: 2rem;
  transition: all .3s;
}

/**/

.oberMenu .ubermenu {
  background: #de1c4a;
}

.oberMenu .ubermenu:after {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 150%;
  background: #de1c4a;
  display: block;
}

.oberMenu .ubermenu .ubermenu-nav {
  display: flex;
  align-items: center;
}

.oberMenu .ubermenu .ubermenu-nav > li > a:hover,
.oberMenu .ubermenu .ubermenu-nav > li > a:focus,
.oberMenu .ubermenu .ubermenu-nav > li > a:active {
  background: 0;
}

.oberMenu .ubermenu .ubermenu-nav > li > a {
  padding: 2rem 2.5rem;
}

.oberMenu .ubermenu .ubermenu-nav > li a {
  color: #fff;
  font-size: 1.6rem;
  font-weight: 300;
}

.oberMenu .ubermenu .ubermenu-nav > li a:hover {
  color: #434242;
}

.oberMenu .ubermenu .ubermenu-nav > li:last-child {
  margin-left: 3rem;
}

.oberMenu .ubermenu .ubermenu-nav > li:last-child > a {
  padding-left: 2rem;
  padding-right: 2rem;
  /*right space to subtriangle*/
}

.ubermenu-sub-indicators .ubermenu-submenu-type-flyout > .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator,
.ubermenu-sub-indicators .ubermenu-submenu-type-stack > .ubermenu-has-submenu-drop.ubermenu-has-submenu-flyout > .ubermenu-target > .ubermenu-sub-indicator {
  margin-top: -2px !important;
  right: 10px !important;
}

.ubermenu-current-menu-item > a {
  color: #fff !important;
  font-weight: 700 !important;
}

.oberMenu .ubermenu .ubermenu-sub-indicator {
  transition: all .3s;
  width: 1.2rem;
  height: .6rem;
  background: url(images/sub_menu_new.svg) no-repeat center;
  background-size: contain;
  color: transparent;
  transform: translateY(-50%);
  margin-top: 0 !important;
  right: 0px !important;
}

.ubermenu .ubermenu-submenu .ubermenu-target {
  padding: 1rem 2rem;
}

.ubermenu-skin-minimal .ubermenu-submenu.ubermenu-submenu-drop {
  border: 0 !important;
}

.oberMenu .ubermenu-submenu > li {
  background: #434242 !important;
}

.oberMenu .ubermenu-submenu > li a:hover {
  color: #de1c4a !important;
}

/*.ubermenu-submenu > li:last-child{border-bottom:5px solid #97291e;padding-bottom:5px}*/

.ubermenu-item-level-2 {
  box-shadow: 1px 11px 29px rgba(0, 0, 0, 0.1);
}

.ubermenu-item-level-2:last-child {
  border-bottom: 0 !important;
}

.ubermenu .ubermenu-submenu-type-flyout > .ubermenu-item {
  min-width: auto !important;
}

.pll-parent-menu-item > a {
  color: #fff !important;
  font-weight: 700 !important;
}

.pll-parent-menu-item > a > span {
  border-bottom: 1px solid #434242;
}

/**/

.flexDaneWrap.myheader .phone {
  margin-right: 5rem;
}

.flexDaneWrap .dane {
  font-size: 1.6rem;
  color: #434242;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flexDaneWrap .dane a {
  color: #434242;
}

.flexDaneWrap .dane a:hover {
  color: #de1c4a;
}

.flexDaneWrap .dane p {
  margin-bottom: 0;
}

.flexDaneWrap .dane.adres img,
.flexDaneWrap .dane.phone img,
.flexDaneWrap .dane.email img {
  margin-right: 2rem;
}

.flexDaneWrap .dane.adres img {
  width: 2rem;
}

.flexDaneWrap .dane.phone img {
  width: 2.6rem;
}

.flexDaneWrap .dane.email img {
  width: 3rem;
}

.colPodSliderIco {
  display: flex;
  align-items: center;
  color: #434242;
  font-family: Montserrat;
  font-size: 1.6rem;
  justify-content: center;
}

.colPodSliderIco .im {
  max-width: 4rem;
  margin-right: 2.5rem;
}

.colPodSliderIco:nth-of-type(2n) {
  justify-content: center;
  border: 1px solid #de1c4a;
  border-top: 0;
  border-bottom: 0;
}

.gobottomlink {
  transition: all .3s;
}

.gobottomlink:hover {
  opacity: 0.5;
}

.gobottomlink img {
  width: 2.6rem;
}

.haslored {
  color: #de1c4a;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 300;
}

.haslogray {
  color: #434242;
  font-family: Montserrat;
  font-size: 4.5rem;
  font-weight: 300;
}

.opis {
  color: #757575;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 300;
  line-height: 1.6;
}

.opis h1,
.opis h2,
.opis h3,
.opis h4,
.opis h5,
.opis h6 {
  margin-bottom: 1rem;
}

.opis.fs16 {
  font-size: 1.6rem;
}

.opis.fs18 {
  font-size: 1.8rem;
}

.opis.dark {
  color: #434242;
}

.opis strong {
  font-weight: 500;
}

.opis.single p {
  margin-bottom: 3rem;
  font-size: 1.6rem;
  line-height: 1.9;
}

.opis p {
  margin-bottom: 2rem;
}

.opis .my_increase {
  font-size: 2.2rem;
  margin-bottom: 3rem;
}

.opis ul {
  padding: 0;
  list-style-position: inside;
}

.opis ul li {
  margin-bottom: 1rem;
}

.opis.opisonas ul {
  list-style: none;
}

.opis.opisonas ul li {
  margin-bottom: 3rem;
  position: relative;
  padding-left: 9rem;
}

.opis.opisonas ul li:after {
  position: absolute;
  top: 1.4rem;
  height: 1px;
  width: 6rem;
  left: 0;
  background: #de1c4a;
  content: '';
}

.opis.aicvonas {
  padding: 4rem 4rem;
}

.rowOnas1 {
  margin-bottom: 8rem;
}

.myBtn {
  display: inline-block;
  color: #434242;
  font-family: Montserrat;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem 3rem;
  border: 1px solid;
  text-align: center;
  transition: all .2s;
  /*width: 100%;
	max-width:25rem;*/
}

.myBtn.w {
  color: #fff;
}

.myBtn.w:hover {
  background: #de1c4a;
  color: #fff;
  border-color: #de1c4a;
}

.myBtn.r {
  color: #de1c4a;
}

.myBtn.r:hover {
  color: #434242;
}

.myBtn.r:before {
  display: none;
}

.myBtn.rez {
  border: 0;
  border-bottom: 1px solid;
  color: #434242;
}

.myBtn.rez:hover {
  color: #de1c4a;
}

.myBtn.rez:before {
  display: none;
}

.myBtn {
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  position: relative;
}

.myBtn:before {
  content: '';
  position: absolute;
  border: solid 1px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: top, right, bottom, left;
  transition-property: top, right, bottom, left;
}

.myBtn:hover:before,
.myBtn:focus:before,
.myBtn:active:before {
  top: -8px;
  right: -8px;
  bottom: -8px;
  left: -8px;
}

.btnRed {
  color: #434242;
  font-family: Montserrat;
  font-size: 1.9rem;
  font-weight: 400;
  position: relative;
  padding-left: 13rem;
}

.btnRed:after {
  transition: all .5s;
  position: absolute;
  top: 50%;
  left: 0;
  width: 10rem;
  content: '';
  height: 1px;
  background: #de1c4a;
}

.btnRed:before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0rem;
  content: '';
  height: 1px;
  background: #434242;
  z-index: 2;
  transition: all .5s;
}

.btnRed:hover {
  color: #de1c4a;
}

.btnRed:hover:before {
  width: 10rem;
}

.btnRed:hover:after {
  right: 0;
  width: 0rem;
}

.btnRed.fw500 {
  font-weight: 500;
}

.oradziesek {
  margin-bottom: 7rem;
}

.czym_nagl {
  color: #434242;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 500;
  position: relative;
  padding-bottom: 2.5rem;
  margin-bottom: 2.5rem;
  margin-top: 2.5rem;
}

.czym_nagl:after {
  content: '';
  position: absolute;
  width: 9rem;
  height: 1px;
  background: #de1c4a;
  bottom: 0;
  left: 0;
}

.colCzym {
  margin-bottom: 8rem;
}

.colCzym .ic {
  width: 9.8rem;
  height: 9.8rem;
}

.newsSection {
  background-color: #faf9fb;
  padding: 4rem 0;
}

.calWrap {
  color: #757575;
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 2rem;
  display: inline-block;
  text-align: center;
}

.calWrap img {
  width: 3.5rem;
  margin-bottom: 1rem;
}

.calWrap.black {
  color: #434242;
}

.colNewsHome .title {
  color: #434242;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 2rem;
  line-height: 1.5;
}

.moreHomeNews a {
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 400;
}

.colFirstNewsLeft .sub {
  border: 1px solid #d4d3d5;
  background-color: #FBFBFC;
  color: #000;
  padding: 3rem;
  height: 100%;
}

.colFirstNewsLeft .c {
  width: 5rem;
  margin-bottom: 1.5rem;
}

.colFirstNewsRight .sub {
  height: 100%;
  background: #fff;
  padding: 2.5rem 3rem;
}

.archiveNews {
  margin-bottom: 16px;
}

.archiveNews .colFirstNewsRight .sub {
  background-color: #fbf9fb;
}

.archiveNews .opis {
  font-size: 1.6rem;
}

.archiveNews.with_thumb .sub {
  background-size: cover;
  background-position: center;
  position: relative;
  color: #fff;
}

.archiveNews.with_thumb .mask {
  background-color: #434242;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapSlickMediaHome {
  position: relative;
}

.wrapSlickMediaHome .prevSlid,
.wrapSlickMediaHome .nextSlid {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  cursor: pointer;
  width: 1.6rem;
  transition: all .2s;
}

.wrapSlickMediaHome .prevSlid:hover,
.wrapSlickMediaHome .nextSlid:hover {
  opacity: 0.5;
}

.wrapSlickMediaHome .prevSlid {
  left: -5rem;
}

.wrapSlickMediaHome .nextSlid {
  right: -5rem;
}

.wrapSlickMediaHome .appDotsMedia {
  margin-top: 6rem;
  text-align: center;
  position: relative;
}

.wrapSlickMediaHome .appDotsMedia .sub {
  display: inline-block;
  position: relative;
}

.wrapSlickMediaHome .appDotsMedia .sub ul.slick-dots {
  position: relative;
  bottom: .5rem;
  top: auto;
}

.wrapSlickMediaHome .slick-list {
  margin-left: -13px;
  margin-right: -13px;
}

.wrapSlickMediaHome .slick-track {
  display: flex;
}

.wrapSlickMediaHome .slick-track .slick-slide {
  display: flex;
  height: auto;
}

.mediaNewsCommonCol {
  padding-left: 13px;
  padding-right: 13px;
}

.mediaNewsCommonCol .sub {
  background-color: #fbf9fb;
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
}

.titleMediaCol {
  margin-bottom: 3rem;
}

.titleMediaCol a {
  color: #434242;
  font-size: 2.5rem;
  font-weight: 500;
}

.titleMediaCol a:hover {
  color: #de1c4a;
}

.colLinkPage {
  margin-bottom: 16px;
}

.colLinkPage .sub {
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  text-align: center;
  padding: 5rem 2rem 5rem 2rem;
}

.colLinkPage .sub .mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(67, 66, 66, 0.62);
  top: 0;
  left: 0;
  z-index: 1;
}

.colLinkPage .sub .haslo1 {
  color: #de1c4a;
  font-family: Montserrat;
  font-size: 2rem;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 0rem;
}

.colLinkPage .sub .haslo2 {
  color: #ffffff;
  font-family: Montserrat;
  font-size: 4.5rem;
  font-weight: 300;
  margin-bottom: 3rem;
}

.colLinkPage .sub .ic img {
  width: 5rem;
}

.logoF {
  width: 27rem;
}

#myModal .modal-dialog,
.mymodal .modal-dialog {
  max-width: 109rem;
  border: 0;
}

#myModal .modal-dialog .modal-content,
.mymodal .modal-dialog .modal-content {
  padding: 5rem 12rem;
  border-radius: 0;
  border: 0;
  box-shadow: 0 0 9rem rgba(0, 0, 0, 0.07);
}

.modal-open,
.modal.fade.show {
  padding-right: 0px !important;
}

.modal-open .innerNavbar,
.modal-open .navbar {
  padding-right: 0 !important;
}

.zgoda_naglowek {
  color: #de1c4a;
  font-family: Montserrat;
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 3rem;
}

.zgoda_tekst {
  color: #434242;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 300;
  margin-bottom: 5rem;
}

.zgoda_tekst strong {
  font-weight: 500;
}

.zgoda_tekst a {
  text-decoration: underline;
}

.zgoda_tekst p {
  margin-bottom: 2rem;
}

.dataZgoda {
  margin-bottom: 5rem;
}

.zgoda_tekst_data {
  color: #434242;
  font-family: Montserrat;
  font-size: 1.6rem;
  font-weight: 300;
}

.colNumbersData .n input {
  width: 5rem;
  border: 0;
  border-bottom: 2px solid #757575;
  margin: 0 1rem;
  text-align: center;
  color: #de1c4a;
  font-size: 1.8rem;
}

.colNumbersData .n input:focus {
  border-color: #de1c4a;
}

.colNumbersData {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}

.colNumbersData ::-webkit-input-placeholder {
  opacity: 1;
  color: #757575;
  font-size: 1rem !important;
  font-weight: 300;
}

.colNumbersData ::-moz-placeholder {
  opacity: 1;
  color: #757575;
  font-size: 1rem !important;
  font-weight: 300;
}

.colNumbersData :-ms-input-placeholder {
  opacity: 1;
  color: #757575;
  font-size: 1rem !important;
  font-weight: 300;
}

.colNumbersData :-moz-placeholder {
  opacity: 1;
  color: #757575;
  font-size: 1rem !important;
  font-weight: 300;
}

.colNumbersData input:focus::-webkit-input-placeholder {
  color: transparent;
}

.colNumbersData input:focus:-moz-placeholder {
  color: transparent;
}

.colNumbersData input:focus::-moz-placeholder {
  color: transparent;
}

.colNumbersData input:focus:-ms-input-placeholder {
  color: transparent;
}

.colNumbersData input[type=number]::-webkit-inner-spin-button,
.colNumbersData input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.colNumbersData input[type=number] {
  -moz-appearance: textfield;
}

.contBread {
  padding-top: 4rem;
  margin-bottom: 4rem;
  color: #757575;
  font-family: Montserrat;
  font-size: 1.6rem;
  font-weight: 300;
}

.contBread a {
  color: #757575;
}

.contBread a:hover {
  color: #de1c4a;
}

.headmain {
  color: #434242;
  font-family: Montserrat;
  font-size: 6rem;
  font-weight: 700;
  margin-bottom: 5rem;
}

.headmain span {
  position: relative;
}

.headmain span:after {
  content: '';
  display: block;
  background: red;
  width: 100%;
  height: 1.8rem;
  background-color: #e2e2e2;
  z-index: -1;
  left: 0;
  bottom: .5rem;
  position: absolute;
}

.sekBannerOnas {
  padding: 10rem 0;
  position: relative;
  color: #ffffff;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 300;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  margin-bottom: 10rem;
}

.sekBannerOnas .mask {
  position: absolute;
  background-color: rgba(67, 66, 66, 0.82);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.sekBannerOnas .bannerop {
  padding-top: 5rem;
  position: relative;
}

.sekBannerOnas .bannerop:after {
  content: '';
  position: absolute;
  height: 1px;
  background: #fff;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 21rem;
}

.contAICVonas {
  margin-bottom: 14rem;
}

.contAICVonas .sub {
  padding: 4rem 0;
}

.contAICVonas .sub:before {
  content: '';
  position: absolute;
  border: 1px solid #de1c4a;
  top: 0;
  left: 0;
  width: calc(100% - 11rem);
  height: 100%;
}

.contAICVonas .acvImg {
  width: 22rem;
}

.contLinkiOnas {
  margin-bottom: 14rem;
}

.nazwa_zakladu {
  color: #434242;
  font-family: Montserrat;
  font-size: 3rem;
  font-weight: 300;
  margin-bottom: 3rem;
  position: relative;
  padding-bottom: 2rem;
}

.nazwa_zakladu:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 1px;
  background: #de1c4a;
  width: 8.7rem;
}

.rowPracownicy {
  margin-bottom: 5rem;
}

.contPracownicy {
  margin-top: 5rem;
}

.colPracownicy {
  margin-bottom: 6rem;
}

.colPracownicy .pracImg {
  width: 22rem;
  height: 22rem;
  object-fit: cover;
  margin-bottom: 3rem;
}

.colPracownicy .name {
  color: #434242;
  font-size: 1.8rem;
  font-weight: 500;
}

.colPracownicy .name,
.colPracownicy .st,
.colPracownicy .m {
  margin-bottom: 1rem;
}

.colPracownicy .st,
.colPracownicy .m {
  font-size: 1.6rem;
  font-weight: 300;
  background-repeat: no-repeat;
  padding-left: 2.5rem;
}

.colPracownicy .st {
  background-image: url(images/icon_man.svg);
  background-size: 1.6rem;
  background-position: left .4rem;
}

.colPracownicy .m {
  background-image: url(images/icon_home.svg);
  background-size: 1.6rem;
  background-position: left .5rem;
}

.colCzlonkowieH {
  margin-bottom: 6rem;
}

.colCzlonkowieH .n {
  color: #434242;
  font-size: 1.8rem;
  font-weight: 500;
  box-shadow: 0 0 3rem rgba(0, 0, 0, 0.05);
  padding: 2rem 15rem 2rem 3rem;
  position: relative;
  margin-bottom: 7rem;
}

.colCzlonkowieH .n img {
  position: absolute;
  width: 9.2rem;
  height: 9.2rem;
  object-fit: cover;
  right: 4.5rem;
  top: -1.2rem;
}

.contczlHon {
  margin-bottom: 6rem;
}

.colwspol {
  margin-bottom: 5rem;
}

.colwspol .imgWrap {
  height: 14rem;
  margin-bottom: 4rem;
}

.colwspol .imgWrap img {
  max-height: 100%;
}

.colwspol .n {
  font-weight: 700;
}

.mapkontakt {
  position: relative;
}

.mapkontakt iframe {
  width: 100%;
  height: 100%;
  min-height: 300px;
  border: 0;
}

.mapkontakt:before {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background: #fff;
  right: 0;
  top: 0;
}

.kontakt .flexDaneWrap {
  flex-direction: column;
}

.kontakt .flexDaneWrap .dane {
  margin-bottom: 5rem;
  font-size: 2rem;
}

.kontakt .flexDaneWrap .dane.adres img {
  width: 3rem;
}

.kontakt .flexDaneWrap .dane.phone img {
  width: 3.4rem;
}

.kontakt .flexDaneWrap .dane.email img {
  width: 3.9rem;
}

.flexgrow1 {
  flex-grow: 1;
}

.kontakt .tw,
.kontakt .linked {
  transition: all .1s;
}

.kontakt .tw:hover,
.kontakt .linked:hover {
  opacity: 0.5;
}

.kontakt .tw {
  margin-right: 4rem;
}

.colRaporty {
  position: relative;
}

.colRaporty:nth-of-type(4n+2) .sub,
.colRaporty:nth-of-type(4n+3) .sub {
  background-color: #fbf9fb;
}

.colRaporty .sub {
  height: 100%;
  padding: 5rem 5rem;
  display: flex;
  flex-direction: column;
}

.colRaporty .subsub {
  /*background:red;*/
  height: 100%;
  display: flex;
  flex-direction: column;
}

.colRaporty .subsub .o {
  padding-left: 8rem;
  margin-bottom: 3rem;
}

.colRaporty .n {
  min-height: 11.5rem;
  color: #434242;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 300;
  background: url(images/download_arrow.svg) no-repeat;
  background-size: 4.2rem;
  background-position: 0 50%;
  padding: 2rem 2rem 2rem 8rem;
}

.mapWrapInbl {
  /*border:1px solid;*/
  width: 100%;
  display: inline-block;
  position: relative;
}

.el_logowrap {
  position: absolute;
}

.el_logowrap .sub {
  position: relative;
  width: 12.4rem;
  height: 7.4rem;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.el_logowrap .sub img {
  max-height: 100%;
}

.el_logowrap .sub .mybg {
  background: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.el_logowrap .sub .mask {
  background: rgba(222, 28, 74, 0.5) url(images/winemask.svg) no-repeat center;
  background-size: 5rem;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all .3s;
}

.el_logowrap .sub .ellipse {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  background: #de1c4a;
  display: block;
  border-radius: 50%;
  transition: all .3s;
}

.el_logowrap .sub .ellipse.left {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 0;
}

.el_logowrap .sub .ellipse.top {
  transform: translate(-50%, -50%);
  top: 0%;
  left: 50%;
}

.el_logowrap .sub .ellipse.right {
  transform: translate(50%, -50%);
  top: 50%;
  right: 0%;
}

.el_logowrap .sub .ellipse.bottom {
  transform: translate(-50%, 50%);
  bottom: 0;
  left: 50%;
}

.el_logowrap .sub:hover .ellipse {
  background: #434242;
}

.el_logowrap .sub:hover .mask {
  width: 100%;
}

.colOpisyCzl .wrapImgC {
  height: 12rem;
  position: relative;
  padding-bottom: 3rem;
  margin-bottom: 3rem;
}

.colOpisyCzl .wrapImgC img {
  max-height: 100%;
}

.colOpisyCzl .wrapImgC:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: #de1c4a;
  width: 9rem;
  display: block;
  height: 1px;
}

.singlehead {
  color: #434242;
  font-family: Montserrat;
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 1.3;
}

.colInArchMeda {
  margin-bottom: 26px;
}

.kDlaMediow .flexDaneWrap {
  justify-content: space-around;
}

.kDlaMediow .flexDaneWrap .dane {
  font-size: 3.3rem;
}

.kDlaMediow .flexDaneWrap .dane.phone img {
  width: 6.4rem;
}

.kDlaMediow .flexDaneWrap .dane.email img {
  width: 6.4rem;
}

.wydZRoku {
  color: #757575;
  font-family: Montserrat;
  font-size: 1.8rem;
  font-weight: 300;
  padding-left: 4rem;
  border-left: 1px solid #de1c4a;
}

.wydZRoku .byYear {
  display: flex;
  justify-content: flex-end;
}

.wydZRoku .byYear li {
  padding: 0;
  list-style: none;
  margin-right: 3rem;
}

.wydZRoku .byYear li.act {
  font-weight: 500;
}

.wydZRoku .byYear li:last-child {
  margin-right: 0;
}

#validMessage {
  /*border:1px solid $red;*/
  color: #434242;
  font-weight: 300;
  margin-top: 3rem;
  margin-bottom: 0rem;
}

#validMessage span {
  background: #de1c4a;
  border-radius: 50%;
  display: block;
  width: 5rem;
  height: 5rem;
  line-height: 5rem;
  color: #fff;
  transition: all .3s;
  opacity: 0;
  transform: scale(0);
}

#validMessage span.act {
  opacity: 1;
  transform: scale(1);
}

.mobileDaneHeader {
  display: none;
}

.mobileDaneHeader .flexDaneWrap.myheader {
  margin-top: 1rem;
}

.mobileDaneHeader .flexDaneWrap.myheader .phone {
  margin-right: 2rem;
}

.mobileDaneHeader .partSocIcons {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mobileDaneHeader .partSocIcons img {
  width: 3rem;
  margin-right: 2rem;
}

.mobileDaneHeader.show {
  display: block;
}

.xMapMobile {
  padding-top: 10px;
}

.xMapMobile button {
  color: #de1c4a;
  font-size: 25px;
}

.imgLogoMobile {
  transform: scale(0.8);
}

/*1200*/

/*992*/

/*768*/

/*576*/

/*480*/

/*350*/

/*1200*/

/*</style>*/

@media (max-width: 1879.98px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 1589.98px) {
  html {
    font-size: 50%;
  }

  .oberMenu .ubermenu .ubermenu-nav > li > a {
    padding-left: 2.0rem;
    padding-right: 2.0rem;
  }

  .oberMenu .ubermenu .ubermenu-sub-indicator {
    right: 2px !important;
  }

  .oberMenu .ubermenu .ubermenu-nav > li:last-child > div {
    padding-left: 2rem !important;
  }

  /**/

  .logo img {
    width: 34rem;
  }

  .Modern-Slider .img-fill .btnWrapSlid {
    margin-bottom: 5rem;
  }

  .Modern-Slider .img-fill .opisslid {
    font-size: 5rem;
  }

  /**/

  .el_logowrap .sub .mask {
    background-size: 4rem;
  }

  .el_logowrap .sub {
    width: 10.4rem;
    height: 6.4rem;
  }
}

@media (max-width: 1199.98px) {
  .oberMenu .ubermenu .ubermenu-nav > li:last-child {
    margin-left: 0;
  }

  .oberMenu .ubermenu .ubermenu-nav > li > a {
    padding-left: 1.0rem;
    padding-right: 1.0rem;
  }

  .logo img {
    width: 25rem;
  }

  .cf1 {
    order: -2;
  }

  .cf3 {
    order: -1;
  }

  footer .flexDaneWrap {
    justify-content: space-between;
  }

  .cf1,
  .cf3 {
    margin-bottom: 15px;
  }

  .colRaporty .sub {
    padding: 3rem;
  }
}

@media (max-width: 991.98px) {
  .el_logowrap .sub .mask {
    background-size: 3rem;
  }

  .el_logowrap .sub {
    width: 7.4rem;
    height: 5.4rem;
  }

  .colLinkPage .sub .haslo2 {
    font-size: 3.5rem;
  }

  .oradzieimghome {
    position: relative;
    right: 15px;
  }

  .siblingLogo {
    width: 100%;
  }

  .logo {
    align-self: stretch;
    position: absolute;
    top: 0;
    left: 15px;
  }

  .logo img {
    width: 15rem;
  }

  .shrink.oberMenu .navbar .logo,
  .shrink.oberMenu .navbar.innerNavbar .logo {
    transform: scale(1);
    top: 5px;
  }

  .Modern-Slider .img-fill .info {
    padding-top: 1rem;
  }

  .Modern-Slider .img-fill .opisslid {
    font-size: 3.5rem;
  }

  .colPodSliderIco {
    justify-content: flex-start !important;
    border: 0 !important;
    margin-bottom: 15px;
  }

  .Modern-Slider .img-fill .n1 {
    margin-top: 3rem;
  }

  .Modern-Slider .img-fill .btnWrapSlid {
    margin-bottom: 3rem;
  }

  .Modern-Slider .img-fill .opisslid {
    margin-bottom: 3rem;
  }

  .wrapSliderHome .prevSlid,
  .wrapSliderHome .nextSlid {
    background: #fff;
    border: 1px solid #de1c4a;
    border-radius: 50%;
    padding: 1rem;
  }

  .wrapSliderHome .prevSlid {
    left: 1rem;
  }

  .wrapSliderHome .nextSlid {
    right: 1rem;
  }

  .kDlaMediow .flexDaneWrap .dane {
    font-size: 2.5rem;
  }

  .kDlaMediow .flexDaneWrap .dane.phone img {
    width: 4rem;
  }

  .kDlaMediow .flexDaneWrap .dane.email img {
    width: 4rem;
  }

  .colRaporty {
    margin-bottom: 15px;
  }

  .colRaporty .sub {
    background-color: #fff !important;
    border: 1px solid #fbf9fb;
  }

  .colRaporty:nth-of-type(even) .sub {
    background-color: #fbf9fb !important;
  }

  .colRaporty .n {
    min-height: auto;
  }

  .colRaporty .subsub .o {
    padding-left: 0;
  }

  #myModal .modal-dialog .modal-content,
  .mymodal .modal-dialog .modal-content {
    padding: 3rem;
  }
}

@media (max-width: 767.98px) {
  /**/

  .mapWrapInbl {
    width: calc(100% + 30px);
    right: 15px;
  }

  /**/

  .colKontaktRight {
    order: -1;
  }

  .kDlaMediow .flexDaneWrap {
    flex-wrap: wrap;
  }

  .kDlaMediow .flexDaneWrap .dane {
    width: 100%;
    margin-bottom: 15px;
    justify-content: center;
  }

  .navigation {
    padding-top: 0;
    padding-bottom: 0;
  }

  .wydZRoku {
    padding-left: 2rem;
    margin-top: 2rem;
  }

  .wydZRoku .byYear {
    justify-content: flex-start;
  }

  .el_logowrap .sub {
    border: 1px solid #de1c4a;
    background: #de1c4a;
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }

  .el_logowrap .sub .mybg {
    display: none;
  }

  .el_logowrap .sub .mask {
    display: none;
  }

  .el_logowrap .sub .ellipse {
    display: none;
  }

  .el_logowrap .sub .mapklientimg {
    display: none;
  }

  .colwspol .imgWrap {
    height: auto;
    margin-bottom: 2rem;
  }

  .colwspol .imgWrap img {
    max-height: 150px;
  }

  .colwspol {
    margin-bottom: 2rem;
  }

  .colCzlonkowieH {
    margin-bottom: 2rem;
  }

  .colCzlonkowieH .n {
    margin-bottom: 4rem;
  }

  .allMetaOnas {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .colPracownicy {
    margin-bottom: 3rem;
  }

  .jakdzialamyimg {
    max-height: 250px;
  }

  .contLinkiOnas {
    margin-bottom: 2rem;
  }

  .contAICVonas {
    margin-bottom: 4rem;
  }

  .sekBannerOnas {
    margin-bottom: 3rem;
  }

  .contAICVonas .sub {
    padding: 2rem 0;
  }

  .opis.aicvonas {
    padding: 0 2rem 2rem 2rem;
  }

  .contAICVonas .sub::before {
    width: 100%;
  }

  .sekBannerOnas {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .opis.opisonas ul li {
    padding-left: 5rem;
  }

  .opis.opisonas ul li:after {
    width: 3rem;
  }

  .contBread {
    padding-top: 2rem;
    margin-bottom: 2rem;
  }

  .headmain {
    font-size: 4rem;
    margin-bottom: 2rem;
  }

  .colOnasRight {
    order: -1;
  }

  .podFooter .colPodfRight span {
    padding: 0 1rem !important;
  }

  footer .flexDaneWrap {
    flex-wrap: wrap;
  }

  footer .flexDaneWrap .dane {
    width: 100%;
    margin-bottom: 15px;
  }

  footer .flexDaneWrap .dane:last-child {
    margin-bottom: 0;
  }

  footer {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  footer {
    margin-top: 3rem;
  }

  .wrapSlickMediaHome .appDotsMedia {
    margin-top: 3rem;
  }

  body {
    padding-top: 60px;
  }

  .oberMenu .ubermenu::after {
    display: none;
  }

  .logo {
    display: none;
  }

  .navbar-expand-md > .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .navbar-expand-md > .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .ubermenu-skin-minimal.ubermenu-responsive-toggle {
    padding: 0 !important;
    display: flex !important;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .ubermenu-skin-minimal.ubermenu-responsive-toggle .fa-bars {
    font-size: 30px !important;
    color: #707070;
    margin: 0 !important;
  }

  .oberMenu .navbar {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    background: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 0px 5px 5px 0px rgba(204, 204, 204, 0.27);
  }

  .logomobile {
    width: 140px;
  }

  .oberMenu .ubermenu .ubermenu-nav > li > a {
    padding: 0 0 15px 0 !important;
    font-size: 15px;
  }

  .oberMenu .ubermenu .ubermenu-nav > li:last-child > div {
    padding: 0 !important;
  }

  .oberMenu .ubermenu .ubermenu-nav > li a {
    font-size: 15px;
  }

  .ubermenu-sub-indicators .ubermenu-has-submenu-drop > .ubermenu-target > .ubermenu-sub-indicator {
    position: relative !important;
    left: 7px !important;
    right: 0 !important;
    transform: none !important;
    top: 5px !important;
  }

  .oberMenu .navbar {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: #fff !important;
  }

  .mobileDaneHeader .flexDaneWrap .dane.adres img,
  .mobileDaneHeader .flexDaneWrap .dane.phone img,
  .mobileDaneHeader .flexDaneWrap .dane.email img {
    margin-right: 1rem;
  }

  .oberMenu .ubermenu .ubermenu-nav > li a {
    font-size: 15px;
    font-weight: 400;
    padding: 8px 8px !important;
  }

  .Modern-Slider .img-fill .n1 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .Modern-Slider .img-fill .opisslid {
    font-size: 3rem;
  }

  .imgSlid {
    max-height: 250px;
  }

  .colPodSliderIco {
    font-size: 2rem;
  }

  .haslogray {
    font-size: 3.5rem;
  }

  .haslored {
    font-size: 1.7rem;
  }

  .colCzym {
    margin-bottom: 2rem;
  }
}

@media (max-width: 350px) {
  .mobileDaneHeader .dane img {
    display: none;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .el_logowrap .sub img {
    width: 100%;
  }
}