/*
	Theme Name: Website WiWi
	Theme URI:
	Author URI: wiwi
	Version: 9.9.9
	License: GNU General Public License, version 3 (GPLv3)
	License URI: license.txt
*/


$red: #de1c4a;
$gray: #434242;
$gray_light: #757575;

$color1: $gray;
$color2: $red;

@mixin v-center($pos:relative) {
  position: $pos;
  top: 50%;
  transform: translateY(-50%);
}

@mixin h-center($pos:relative) {
  position: $pos;
  left: 50%;
  transform: translateX(-50%);
}

@mixin overlay() {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@mixin transit($what: all, $when: 0.25s, $how: ease) {
  transition: $what $when $how;
}

@for $i from 1 through 20 {
  .#{mb}#{$i*5} {
    margin-bottom: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{mt}#{$i*5} {
    margin-top: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{pb}#{$i*5} {
    padding-bottom: #{$i*5}px;
  }
}

@for $i from 1 through 20 {
  .#{pt}#{$i*5} {
    padding-top: #{$i*5}px;
  }
}

@for $i from 1 through 10 {
  .#{z}#{$i} {
    z-index: #{$i};
  }
}

@keyframes pulsered {
	0% {
		box-shadow: 0 0 0 0 rgba(red, 0.4);
	}
	20% {
		box-shadow: 0 0 0 20px rgba(red, 0);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(red, 0);
	}
}

$colgutters-list:8 10 13 20 25 30 40 60 10 50 38;
@each $current-gutter in $colgutters-list {
    $i: index($colgutters-list, $current-gutter);
    .col#{$current-gutter} { 
        padding-right: #{$current-gutter}px;
		padding-left: #{$current-gutter}px;
    }
    .row#{$current-gutter} { 
        margin-right: -#{$current-gutter}px;
		margin-left: -#{$current-gutter}px;
    }	
}

@keyframes showImage {
	0% {
		opacity: 0;
	}
	
	100% {
		opacity: 1;
	}
}


@keyframes hideImage {
	0% {
		opacity: 1;
	}
	
	100% {
		opacity: 0;
	}
}

@keyframes scaleImage {
	0% {
		transform: scale(0);
		transform-origin: center;
	}
	
	100% {
		transform: scale(1);
		transform-origin: center;
	}
}

@keyframes scaleBackImage {
	0% {
		transform: scale(1);
		transform-origin: center;
	}
	
	100% {
		transform: scale(0);
		transform-origin: center;
	}
}

/**/

@keyframes translateXBack {
	0% {
		transform: translateX(0px);
		transform-origin: center;
	}
	
	100% {
		transform: translateX(-1000px);
		transform-origin: center;
	}
}

@keyframes translateXBack1 {
	0% {
		transform: translateX(0px);
		transform-origin: center;
	}
	
	100% {
		transform: translateX(1000px);
		transform-origin: center;
	}
}

@keyframes translateXForward {
	0% {
		transform: translateX(-1000px);
		transform-origin: center;
	}
	
	100% {
		transform: translateX(0px);
		transform-origin: center;
	}
}

@keyframes translateXForward1 {
	0% {
		transform: translateX(1000px);
		transform-origin: center;
	}
	
	100% {
		transform: translateX(0px);
		transform-origin: center;
	}
}